import request from "@/utils/request";

/**
 * Find categories with subcategories.
 */
export function getActivityCategories() {
  return request({
    url: "api/v1/activities-categories",
    method: "GET",
  });
}

/**
 * Find categories with subcategories with pagination feature.
 *
 * @param {object} [params]
 * @param {number} params.limit
 * @param {number} params.page
 */
export function getActivityCategoriesPagination(params) {
  return request({
    url: "api/v1/activities-categories/page",
    method: "GET",
    params,
  });
}

/**
 * Find single category.
 *
 * @param {number} category_id
 */
export function getActivityCategory(category_id) {
  return request({
    url: `api/v1/activities-categories/${category_id}`,
    method: "GET",
  });
}

/**
 * Update category.
 *
 * @param {number} category_id
 * @param {object} data
 * @param {string} data.name_zh
 * @param {string} data.name_en
 */
export function updateActivityCategory(category_id, data) {
  return request({
    url: `api/v1/activities-categories/${category_id}`,
    method: "PATCH",
    data,
  });
}

/**
 * Create category.
 *
 * @param {object} data
 * @param {string} data.name_zh
 * @param {string} data.name_en
 */
export function createActivityCategory(data) {
  return request({
    url: "api/v1/activities-categories",
    method: "POST",
    data,
  });
}

/**
 * Delete category.
 *
 * @param {number} category_id
 */
export function deleteActivityCategory(category_id) {
  return request({
    url: `api/v1/activities-categories/${category_id}`,
    method: "DELETE",
  });
}
